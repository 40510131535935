<template>
  <li>
    <a :href="'#/portfolio:' + post.postid" ref="project" class="project-link" @mouseover="postOver()" @mouseout="postOut()" @focusin="postOver()" @focusout="postOut()">
      <figure>
        <div ref="posticon" class="project-icon">
          <img :src="post.postthumburl" alt="">
        </div>
        <figcaption ref="posttitle">{{ post.posttitle }}</figcaption>
      </figure>
    </a>
  </li>
</template>

<script>
//import { ref } from "vue";
import { gsap } from "gsap";

//const postdata = ref({});

export default {
  name: "PortfolioItem",
  props: {
    post: Object,
  },
  data() {
    return {
      //postdata
    }
  },
  mounted(){
    window.addEventListener('hashchange', () => {
      let scope = this;
      let route = window.location.hash.split(':');
      if(route[1] == this.$props.post.postid && route[0] == '#/'){
        this.$refs.project.focus();
        gsap.delayedCall(1.5, function(){
          //window.location.hash = "/";
          scope.$refs.project.blur();
          scope.postOut();
      });
      }
		});
  },
  methods: {
    postOver() {
      gsap.killTweensOf(this.$refs.posticon);
      gsap.killTweensOf(this.$refs.posttitle);
      gsap.to(this.$refs.posticon, {duration: .5, scale: .9, opacity: .65, ease: "power2.out"});
      gsap.to(this.$refs.posttitle, {duration: .5, y: -20, opacity: 1, ease: "power2.out"});
    },

    postOut() {
      gsap.killTweensOf(this.$refs.posticon);
      gsap.killTweensOf(this.$refs.posttitle);
      gsap.to(this.$refs.posticon, {duration: .5, scale: 1, opacity: 1, ease: "power2.out"});
      gsap.to(this.$refs.posttitle, {duration: .5, y: 0, opacity: 0, ease: "power2.in"});
    },

    showPost(post) {
      //postdata.value = toRaw(this.$props.post);
      this.$root.$refs.foregroundcontent.showPost(post);
    }
  },
};
</script>

<template>
  <section id="portfolio" ref="portfolio" class="portfolio-section" tabindex="0">
    <div class="outer-content">
      <div class="inner-content">
        <h3 ref="portfoliotitle">PORTFOLIO</h3>
        <p>Here are some of my favorite projects in which I had a lead design/development role. Please contact me to request a password for live examples, or if you would like more information.</p>
        <ul id="projects" ref="projects" class="flex-3-col center project-list">
          <PortfolioItem v-for="(post, index) in portfolioposts" :post="post" :key="index" ></PortfolioItem>
        </ul>
      </div>
      <div class="portfolio-background-outer">
        <div class="portfolio-background" data-speed="auto"></div>
      </div>
    </div>
  </section>
</template>

<script>
import PortfolioItem from "./PortfolioItem.vue";

import { ref } from "vue";
//import { gsap } from "gsap";

const portfolioposts = ref(null);


export default {
  name: "PortfolioSection",
  components: { 
    PortfolioItem
  },
  data() {
    return {
      portfolioposts
    }
  },
  mounted(){
    this.getPosts();
  },
  methods: {
    getPosts: function() {
      fetch('https://www.keefstudio.com/wp-json/keefstudio/v1/getposts/', {
        method: "GET",
        /*headers: {
          "X-RapidAPI-Key": 'your-api-key',
          "X-RapidAPI-Host": 'facts-by-api-ninjas.p.rapidapi.com',
        },*/
      })
        .then((response) => {
          response.json().then((data) => {
            data = JSON.parse(data);
            //console.log(data);
            portfolioposts.value = data;
            this.$root.$refs.foregroundcontent.setPosts(data);
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },
};
</script>

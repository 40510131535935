<template>
  <!-- Orbit Paths Animated -->
  <div id="orbitpath" class="orbit-path" :class="{ activated: !orbitactive }">
    <div id="orbitsvg" class="orbit-svg">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2130.11 727.38">
        <g>
          <path id="orbit1" ref="orbit1" class="orbit-1" d="M3.48,3.59s743.06,718.79,1543.65,718.79c594.57,0,790.42-475.17,301.21-491.04-366.6-11.89-784.04,131.64-784.04,131.64"/>
        </g>
        <g>
          <path id="orbit2" ref="orbit2" class="orbit-2" d="M3.48,3.59s743.06,718.79,1543.65,718.79c594.57,0,790.42-475.17,301.21-491.04-366.6-11.89-784.04,131.64-784.04,131.64"/>
        </g>
        <g>
          <path id="orbit3" ref="orbit3" class="orbit-3" d="M3.48,3.59s743.06,718.79,1543.65,718.79c594.57,0,790.42-475.17,301.21-491.04-366.6-11.89-784.04,131.64-784.04,131.64"/>
        </g>
      </svg>
    </div>
  </div>

  <!-- 3d Animations -->
  <div id="orbiter" ref="orbiter" class="orbiter" v-show="showbeacon"></div>
  <div id="starfield" ref="starfield" class="star-field"></div>
</template>

<script>
import { ref } from "vue";
import * as THREE from "three";
import { OBJLoader } from "three/addons/loaders/OBJLoader.js";
import { gsap } from "gsap";

const showbeacon = ref(false);

let scene, light, camera, renderer, beacon, rotatebeacon,
scene2, light2, camera2, renderer2, beacon2, rotatebeacon2,
scene3, camera3, renderer3;

let cameraX = 0, cameraY = 0, mouseX = 0, mouseY = 0, currX = 0, currY = 0;

export default {
  name: "ScrollAnimations",
  props: {
    orbitactive: Boolean,
  },
  data() {
    return {
      showbeacon
    }
  },
  mounted(){
    this.loadOrbiter();
    this.makeStars();
  },
  methods: {
    loadOrbiter: function(){
      //Scene 1 Setup
      scene = new THREE.Scene();
      light = new THREE.PointLight(0xcccccc, 800);
      light.position.set(-10, -2, 8);
      //light = new THREE.DirectionalLight(0xFFFFFF, 10);
      //light.position.set(-5, -5, 8);
      //light.target.position.set(0, 0, 0);
      //light.castShadow = true;
      //light.shadow.radius = 3.5;
      //light.shadow.bias = -.0009;
      //light.shadow.mapSize.width = 512;
      //light.shadow.mapSize.height = 512;
      scene.add(light);
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.z = 2;
      renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
      renderer.setSize(window.innerWidth, window.innerHeight);
      //renderer.shadowMap.enabled = true;
    	//renderer.shadowMap.type = THREE.VSMShadowMap;
      //renderer.shadowMap.renderReverseSided = false;
      this.$refs.orbiter.appendChild(renderer.domElement);

      //Scene 2 Setup
      scene2 = new THREE.Scene();
      light2 = new THREE.PointLight(0x000000, 6000);
      light2.position.set(2.5, 7.5, 15);
      scene2.add(light2);
      camera2 = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera2.position.z = 2;
      renderer2 = new THREE.WebGLRenderer({ alpha: true });
      renderer2.setSize(window.innerWidth, window.innerHeight);
      this.$parent.$refs.introbg.appendChild(renderer2.domElement);

      let material = new THREE.MeshBasicMaterial({
          color: 0x131313,
          wireframe: true
      });

      //Scene 1 Objects
      // plane geometry
			/*const geometryP = new THREE.PlaneGeometry(10, 10);
			const materialP = new THREE.ShadowMaterial();
			const plane = new THREE.Mesh(geometryP, materialP);
      materialP.opacity = 0.35;
			plane.castShadow = false;
			plane.receiveShadow = true;
      plane.position.set(0, 0, -.3);
			scene.add(plane);*/
      
      const objLoader = new OBJLoader();

      objLoader.load(
        './beacon-decimated2.obj',
        (object) => {
            beacon = object;
            
            beacon.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                  child.castShadow = true;
                }
            } );

            //beacon.castShadow = true;
            
            beacon.name = 'beacon';
            beacon.scale.set(.05,.05,.05);
            beacon.rotation.set(Math.PI / 1.5, -Math.PI / 4, 0);
            beacon.castShadow = true;
            scene.add(beacon);
            this.setupBeacon(beacon);
        },
        (error) => {
            console.log(error)
        }
      );

      //Scene 2 Objects
      objLoader.load(
        './beacon-decimated2.obj',
        (object) => {
            beacon2 = object;
            
            beacon2.traverse( function ( child ) {
                if ( child instanceof THREE.Mesh ) {
                    child.material = material;
                }
            } );
            
            beacon2.name = 'beacon2';
            beacon2.rotation.set(Math.PI / 1.5, Math.PI / 4, 0);
            beacon2.position.set(-3.3, 2.6, -1);
            scene2.add(beacon2);
            this.setupBeacon2(beacon2);
        },
        (error) => {
            console.log(error)
        } 
      );
      
      window.addEventListener('resize', function onWindowResize() {
          camera.aspect = window.innerWidth / window.innerHeight;
          camera.updateProjectionMatrix();
          renderer.setSize(window.innerWidth, window.innerHeight);
          renderer.render(scene, camera);

          camera2.aspect = window.innerWidth / window.innerHeight;
          camera2.updateProjectionMatrix();
          renderer2.setSize(window.innerWidth, window.innerHeight);
          renderer2.render(scene2, camera2);

      }, false);

      window.addEventListener('mousemove', function(e){
        mouseX = (window.innerWidth * .5) - e.clientX;
        mouseY = (window.innerHeight * .5) - e.clientY;
      });
    },

    setupBeacon: function(beacon){
      let scope = this;
      rotatebeacon = gsap.to(beacon.rotation, { ease: "none", duration:.01, repeat:-1, onRepeat: function(){
        beacon.rotation.z += .005;
        scope.chaseBeacon();
        renderer.render(scene, camera);
      }})
      rotatebeacon.pause();
      renderer.render(scene, camera);
      gsap.set(beacon.position, {x:-.2, y:.1, z:-4.5});
      gsap.set(this.$refs.orbiter, {opacity:0});
      this.$parent.triggerOrbiter(beacon);
    },

    setupBeacon2: function(beacon2){
      rotatebeacon2 = gsap.to(beacon2.rotation, { ease: "none", duration:.04, repeat:-1, onRepeat: function(){
        beacon2.rotation.z += .006;
        renderer2.render(scene2, camera2);
      }})
      renderer2.render(scene2, camera2);
      rotatebeacon2.play();
    },

    toggleBeacons: function(thisbeacon){
      if(thisbeacon == "beacon"){
        rotatebeacon.paused(!rotatebeacon.paused());
        this.$nextTick(function(){
          showbeacon.value = !rotatebeacon.paused();
        });
        
      }
      if(thisbeacon == "beacon2"){
        rotatebeacon2.paused(!rotatebeacon2.paused());
      }
    },

    chaseBeacon: function(){
      let stopX = cameraX - (mouseX * .0003);
      let stopY = cameraY + (mouseY * .0003);
      camera.position.x += (stopX - currX) * .03;
      camera.position.y += (stopY - currY) * .03;
      currX = camera.position.x;
      currY = camera.position.y;
    },

    makeStars: function(){
      scene3 = new THREE.Scene(); //starfield
      camera3 = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera3.position.z = 2;
      renderer3 = new THREE.WebGLRenderer({ alpha: true });
      renderer3.setSize(window.innerWidth, window.innerHeight);
      this.$refs.starfield.appendChild(renderer3.domElement);
      gsap.set(this.$refs.starfield, {opacity:0});

      const circlegeometry = new THREE.CircleGeometry(.015, 5); 
      const circlematerial = new THREE.MeshBasicMaterial( { 
          transparent: true,
          opacity: 0.3,
          color: 0xffffff
      });
      const circlematerial2 = new THREE.MeshBasicMaterial( { color: 0xf98c4b } );

      let max = 15;
      let min = -20;
      let maxy = -3;
      let miny = -20;
      let maxz = 6;
      let minz = -10;

      for(let i=0; i<22; i++){
          let thiscircle = new THREE.Mesh( circlegeometry, circlematerial );
          thiscircle.position.set(
              Math.random().toPrecision(3) * (max - min) + min,
              Math.random().toPrecision(3) * (maxy - miny) + miny,
              Math.random().toPrecision(3) * (maxz - minz) + minz
          );
          /*gsap.to(thiscircle.position, {duration: 6, z:"-=10", ease:"none", repeat:-1, immediateRender: false, delay:Math.random(), onRepeat: function(){
                  gsap.set(this._targets[0], {x:Math.random() * (max - min) + min, y: Math.random() * (maxy - miny) + miny});
                  //console.log(this._targets[0]);
              }
          });*/
          scene3.add( thiscircle );
      }

      for(let i=0; i<5; i++){
          let thiscircle = new THREE.Mesh( circlegeometry, circlematerial2 );
          thiscircle.position.set(
              Math.random().toPrecision(3) * (max - min) + min,
              Math.random().toPrecision(3) * (maxy - miny) + miny,
              Math.random().toPrecision(3) * (maxz - minz) + minz
          );

          /*gsap.to(thiscircle.position, {duration: 2, z:"-=10", ease:"none", repeat:-1, immediateRender: false, delay:Math.random(), onRepeat: function(){
                  gsap.set(this._targets[0], {x:Math.random() * (max - min) + min, y: Math.random() * (maxy - miny) + miny});
                  //console.log(this._targets[0]);
              }
          });*/

          scene3.add( thiscircle );
      }

      window.addEventListener('resize', function() {
        camera3.aspect = window.innerWidth / window.innerHeight;
        camera3.updateProjectionMatrix();
        renderer3.setSize(window.innerWidth, window.innerHeight);
        renderer3.render(scene3, camera3);
      }, false);

      function animatestars() {
        gsap.delayedCall(.01, function(){
          scene3.traverse( function(node) {
            if ( node instanceof THREE.Mesh ) {
              node.position.x += .003;
              node.position.z -= .016;
              if(node.position.x > max || node.position.z < minz){
                node.position.x = Math.random().toPrecision(3) * (max - min) + min;
                node.position.y = Math.random().toPrecision(3) * (maxy - miny) + miny;
                node.position.z = Math.random().toPrecision(3) * maxz;
              }
            }
          });
          camera3.position.y = Math.round(window.pageYOffset) * -.0035;
          renderer3.render(scene3, camera3);
          animatestars();
        });
      }
      
      animatestars();
    },
  },
};
</script>
<template>
  <section id="tech" ref="tech" class="tech-section overlay-pattern" data-speed="1">
    <div class="outer-content">
      <div class="inner-content center">
        <p>Not to name drop, but over the years I have come to enjoy using and rely on certain languages, libraries, and applications. Here are some are essentials that I know pretty well:</p>
        <div class="side-line-heading">
          <h4 id="techtitle" ref="techtitle" class="tech-title">{{ devtooltitle }}</h4>
        </div>
        <ul id="techlist" class="flex-inline tech-list">
          <li v-for="(devtool, index) in devtools" :ref="devtool.index" :key="devtool.index" class="tech-list-item">
            <a :href="devtool.link" target="_blank" @mouseover="devtoolover($event.target, index)" @mouseout="devtoolout($event.target, index)" @focusin="devtoolover($event.target, index)" @focusout="devtoolout($event.target, index)">
              <figure :name="devtool.title">
                <div :ref="'techicon' + index" class="tech-list-icon">
                  <img :src="`./tech-icons/${devtool.icon}`" alt=""/>
                </div>
                <figcaption>{{ devtool.info }}</figcaption>
              </figure>
            </a>
          </li>
        </ul>
        <div id="techsub" ref="techsub" class="tech-sub">{{ devtoolinfo }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText";
gsap.registerPlugin( SplitText );

const devtools = [
    {title: "Photoshop", info:"Photo / Bitmap", icon:"photoshop.svg", link: "https://www.adobe.com/products/photoshop.html" },
    {title: "Illustrator", info:"Vector Graphics", icon:"illustrator.svg", link: "https://www.adobe.com/products/illustrator.html" },
    {title: "Premiere", info:"Video Editing", icon:"premiere.svg", link: "https://www.adobe.com/products/premiere.html" },
    {title: "After Effects", info:"Motion Design", icon:"after-effects.svg", link: "https://www.adobe.com/products/aftereffects.html" },
    {title: "HTML", info:"Web Markup", icon:"html.svg", link: "https://www.w3schools.com/html/default.asp" },
    {title: "CSS", info:"Markup Styles", icon:"css.svg", link: "https://www.w3schools.com/css/default.asp" },
    {title: "JavaScript", info:"Browser Scripting", icon:"javascript.svg", link: "https://www.w3schools.com/js/default.asp" },
    {title: "PHP", info:"Server Scripting", icon:"php.svg", link: "https://www.w3schools.com/php/default.asp" },
    {title: "WordPress", info:"Content Management", icon:"wordpress.svg", link: "https://wordpress.org/" },
    {title: "Vue JS", info:"Application Framework", icon:"vuejs.svg", link: "https://vuejs.org/" },
    {title: "GreenSock", info:"Animation Platform", icon:"greensock.svg", link: "https://gsap.com/" }
];

let titlesplit;
let reverttext = null;

export default {
  name: "TechSection",
  data() {
    return {
      devtooltitle: "Core Tech",
      devtoolinfo: "Front-end Essentials",
      devtools
    }
  },
  mounted(){
      //this.testgsap();
    
  },
  methods: {
    testgsap: function(){
      let titlesplit = new SplitText(this.$refs.gsaptest, {type: "chars"});
      gsap.from(titlesplit.chars, {
        duration: .35,
        y: -15, 
        opacity: 0, 
        stagger: 0.04
      });
    },

    devtoolover(target, index) {
      let thistarget = this.$refs["techicon" + index];
      if(reverttext != null){
        reverttext.kill();
        reverttext = null;
      }

      this.devtooltitle = this.devtools[index].title;
      this.devtoolinfo = this.devtools[index].info;

      gsap.killTweensOf(thistarget);
      gsap.to(thistarget, {
          duration: .35,
          opacity: 1, 
          ease:"power2.out"
      });

      gsap.killTweensOf(this.$refs.techtitle);
      gsap.set(this.$refs.techtitle, {
          opacity: 1,
          y: 0
      });

      this.$nextTick(() => {
        titlesplit = new SplitText(this.$refs.techtitle, {type: "chars"});
        gsap.from(titlesplit.chars, {
          duration: .35,
          y: -15, 
          opacity: 0,
          stagger: .03
        });
      });

      gsap.set(this.$refs.techsub, {
          opacity: 0,
          scale: 0.65
      });

      gsap.killTweensOf(this.$refs.techsub);
      gsap.to(this.$refs.techsub, {
          duration: 0.5,
          opacity: 1,
          scale: 1,
          ease: "power2.inOut",
      });
    },

    devtoolout(target, index) {
      let thistarget = this.$refs["techicon" + index];
      gsap.killTweensOf(thistarget);
      gsap.to(thistarget, {
          duration: .35,
          opacity: .5, 
          ease:"power2.out"
      });

      if(reverttext != null){
          reverttext.kill();
          reverttext = null;
      }
      
      let scope = this;
      reverttext = gsap.delayedCall(0, function(){
          gsap.killTweensOf(scope.$refs.techtitle);
          gsap.to(scope.$refs.techtitle, {
              duration: 1,
              opacity: 0,
              onComplete: function(){
                  titlesplit.revert();
                  scope.devtooltitle = "Core Tech";
                  gsap.set(scope.$refs.techtitle, {opacity:0})
                  gsap.to(scope.$refs.techtitle, {
                      duration: 1,
                      opacity: 1,
                  });
              }
          });

          gsap.killTweensOf(scope.$refs.techsub);
          gsap.to(scope.$refs.techsub, {
              duration: 1,
              scale: 1,
              opacity: 0,
              onComplete: function(){
                  scope.devtoolinfo = "Front-end Essentials";
                  gsap.to(scope.$refs.techsub, {
                      duration: 1,
                      opacity: 1,
                  });
              }
          });
      });
    },
  },
};
</script>

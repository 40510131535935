<template>
<div class="foreground-layout">
	<!--<svg id="angleleft" ref="angleleft" class="bg-angle" :class="{open: !innerpageshowing}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.09 91.16">
		<g>
			<g>
				<polygon points="320.09 0 160.16 0 159.94 0 0 0 0 91.16 160.16 91.16 320.09 0"/>
			</g>
		</g>
	</svg>
	<svg id="angleright" ref="angleright" class="bg-angle" :class="{open: !innerpageshowing}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320.09 91.16">
		<g>
			<g>
				<polygon points="0 91.16 159.94 91.16 160.16 91.16 320.09 91.16 320.09 0 159.94 0 0 91.16"/>
			</g>
		</g>
	</svg>-->
  <div id="angleleft" ref="angleleft" class="bg-angle" :class="{open: !innerpageshowing}"></div>
	<div id="angleright" ref="angleright" class="bg-angle" :class="{open: !innerpageshowing}">
  </div>
	<div id="innerpagewrap open" ref="innerpagewrap" class="inner-page-wrap">
		<div id="innerpage open" ref="innerpage" class="inner-page">
      <div id="logo" ref="logo" class="keef-studio" @click="logoClick(innerpageshowing)" :class="{hidden: !showlogo }" tabindex="0">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306.74 93.01">
            <g>
              <path d="M168.38,38.2c.09-.63,1.38-2.05,11.49-2.05H191.6l1-7H180.12a58.86,58.86,0,0,0-15.06,1.55c-5.72,1.55-8.85,3.89-9.31,7.13l-2.13,15.05c-.45,3.2,2,5.78,7.14,7.33a47.37,47.37,0,0,0,14.09,1.94h26.74l1-7H176.05c-6.86,0-9-1-9.54-1.68a.57.57,0,0,1-.15-.47l.54-3.85h36.5l1-7H167.82Z"></path>
              <path d="M222.82,38.2c.09-.63,1.39-2.05,11.49-2.05H246l1-7H234.57a58.81,58.81,0,0,0-15.06,1.55c-5.72,1.55-8.86,3.89-9.31,7.13l-2.13,15.05c-.46,3.2,2,5.78,7.13,7.33a47.42,47.42,0,0,0,14.09,1.94h25.63l1-7H230.49c-6.86,0-9-1-9.54-1.67a.6.6,0,0,1-.15-.48l.54-3.85h35.39l1-7H222.26Z"></path>
              <path class="cls-1" d="M277,42.15l.49-3.61c.07-.52,1.13-1.71,9.27-2.23,2.68-.17,7-.16,12.57-.16h.43l1-7h-12a60.16,60.16,0,0,0-14.37,1.49c-5.9,1.51-9,3.71-9.39,6.65l-3.51,24.86h12.73l1.83-13h29.73l1-7Z"></path>
              <polygon points="158.33 29.15 140.44 29.15 116.76 41.77 115.65 49.72 136.69 63.15 154.48 63.15 126.58 45.74 158.33 29.15"></polygon>
              <polygon points="97.82 62.15 109.58 62.15 114.45 28.15 102.7 28.15 97.82 62.15"></polygon>
              <path d="M99,86.83a2.44,2.44,0,0,0,1.47-.42,1.28,1.28,0,0,0,.58-1,.91.91,0,0,0-.37-.74,6.4,6.4,0,0,0-1.33-.72,6.51,6.51,0,0,1-2.29-1.47,2.78,2.78,0,0,1-.7-1.93,2.94,2.94,0,0,1,1.22-2.36,4.7,4.7,0,0,1,3.05-1,6.11,6.11,0,0,1,2,.36,7.13,7.13,0,0,1,1.9,1l-1.06,1.76a7.14,7.14,0,0,0-1.55-.83,3.71,3.71,0,0,0-1.37-.29,2.42,2.42,0,0,0-1.34.33,1,1,0,0,0-.51.82,1,1,0,0,0,.39.79,4.76,4.76,0,0,0,1.23.68,7.24,7.24,0,0,1,2.37,1.47,2.65,2.65,0,0,1,.7,1.92,3.19,3.19,0,0,1-1.28,2.55,4.87,4.87,0,0,1-3.2,1,6.86,6.86,0,0,1-2.27-.41,7.62,7.62,0,0,1-2.08-1.14l1.06-1.76a7.3,7.3,0,0,0,1.74,1A4.48,4.48,0,0,0,99,86.83Z"></path>
              <path d="M118.6,77.39l-.46,2h-3.7l-2.11,9.17h-2.42L112,79.42h-3.7l.46-2Z"></path>
              <path d="M132.68,77.39,131,84.61a5.54,5.54,0,0,1-1.83,3.17,5.28,5.28,0,0,1-3.43,1.1,4.91,4.91,0,0,1-3.25-1,3.37,3.37,0,0,1-1.18-2.72,7.1,7.1,0,0,1,.09-1.06c.07-.39.18-.91.34-1.54l1.31-5.18h2.42l-1.36,5.52c-.14.57-.24,1-.3,1.3a3.7,3.7,0,0,0-.09.7,1.8,1.8,0,0,0,.58,1.42,2.16,2.16,0,0,0,1.55.53,2.53,2.53,0,0,0,1.78-.63,3.57,3.57,0,0,0,1-1.88l1.65-7Z"></path>
              <path d="M139.59,88.59h-4.16l2.6-11.2h3a6.17,6.17,0,0,1,4.16,1.29,4.56,4.56,0,0,1,1.48,3.64,5.83,5.83,0,0,1-.46,2.3,6.29,6.29,0,0,1-1.34,2,6.43,6.43,0,0,1-2.33,1.47A8.49,8.49,0,0,1,139.59,88.59Zm4.66-6.27a2.77,2.77,0,0,0-.89-2.17,3.54,3.54,0,0,0-2.45-.79H140l-1.69,7.26h1.31a4.69,4.69,0,0,0,3.33-1.23A4.06,4.06,0,0,0,144.25,82.32Z"></path>
              <path d="M154.17,80.33l-1.9,8.26H150l1.91-8.26Zm.74-2.68a1.42,1.42,0,0,1-.42,1,1.43,1.43,0,0,1-1,.41,1.37,1.37,0,0,1-.88-.29,1,1,0,0,1-.35-.8,1.42,1.42,0,0,1,.42-1,1.47,1.47,0,0,1,1-.41,1.33,1.33,0,0,1,.88.29A1,1,0,0,1,154.91,77.65Z"></path>
              <path d="M160.06,79.1a6.75,6.75,0,0,1,2.1-1.43,6.09,6.09,0,0,1,2.44-.5,4.94,4.94,0,0,1,3.68,1.44,5.07,5.07,0,0,1,1.41,3.71,6.23,6.23,0,0,1-.49,2.45,6.79,6.79,0,0,1-1.41,2.11,6.68,6.68,0,0,1-2.11,1.43,6,6,0,0,1-2.44.5,4.93,4.93,0,0,1-3.67-1.44,5.07,5.07,0,0,1-1.41-3.71,6.23,6.23,0,0,1,.49-2.45A6.62,6.62,0,0,1,160.06,79.1Zm7.17,3.22a3.38,3.38,0,0,0-.75-2.27,2.39,2.39,0,0,0-1.91-.87,3.57,3.57,0,0,0-2.78,1.33,4.62,4.62,0,0,0-1.17,3.15,3.42,3.42,0,0,0,.74,2.27,2.42,2.42,0,0,0,1.92.87,3.58,3.58,0,0,0,2.78-1.33A4.62,4.62,0,0,0,167.23,82.32Z"></path>
              <path d="M26.26,49.62,116.26,0h-46L5.93,36.83,0,64.37H0L45.41,93,71.56,78.65l-45.3-29ZM7,37.64,70.58,1.25H111.4L25.65,48.52,1.81,61.92Z"></path>
            </g>
        </svg>
      </div>
      <a id="closeicon" ref="closeicon" class="close-icon" v-if="innerpageshowing && notintro" href="#/portfolio" @click.prevent="closeClick()" tabindex="1">
        <svg id="close-icon" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.2 74.4">
          <g>
            <path style="fill:none" d="M90.2,1h-15.9l-29.7,29.7,7.9,7.9L90.2,1h0Z"/>
            <polygon points="89.2 73.4 16.9 1 1 1 37.67 37.73 2 73.4 17.9 73.4 45.57 45.63 73.3 73.4 89.2 73.4"/>
          </g>
        </svg>
      </a>
			<div id="innerpagecontent" ref="innerpagecontent" class="inner-page-content" :class="{hidden: !postloaded}" tabindex="1">
          <Transition>
          <div v-show="postloaded" class="content-wrap">
            <div class="hgroup" :class="{hidden: !postloaded}"> <div id="posticon" class="post-icon"><img :src="postdata.postthumb" alt=""></div>
              <h2 id="posttitle" class="post-title">{{ postdata.posttitle }}</h2>
              <div id="postcategory" class="post-category">{{ postdata.postcategory }}</div>
            </div>
            <div id="postcontent" class="post-content" :class="{hidden: !postloaded}" v-html="postdata.postcontent"></div>
            <div v-if="showvideo" id="postvideo" ref="postvideo" class="post-video" @click="showVideo('close')">
              <div v-if="showvideo" id="video" ref="video" class="video" :class="{videoloaded: loadvideo}" @click.prevent.stop>
                <video v-if="loadvideo" :src="postdata.videolink" controls="true" autoplay="true">Sorry, that didn't work.</video>
            </div>
            </div>
            <div v-if="innerpageshowing && notintro" id="postlinks" class="post-links">
              <ul>
                <li class="post-link" v-if="postdata.videolink" :title="!showvideo ? 'Watch video' : 'Close video'">
                  <a :href="postdata.videolink" target="_blank" @click.prevent="showVideo('toggle')" tabindex="2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M157,39c-14.8-9.1-33.4-9.4-48.5-0.9S84,62.6,84,80v352c0,17.4,9.4,33.4,24.5,41.9s33.7,8.1,48.5-0.9l288-176 c14.3-8.7,23-24.2,23-41s-8.7-32.2-23-41L157,39z"/>
                </svg>
                    <!--<span class="postlink-title">Watch video</span>-->
                  </a>
                </li>
                <li class="post-link" v-if="postdata.sitelink">
                  <a :href="postdata.sitelink" target="_blank" title="Visit site" tabindex="2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                      <path d="M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z"/>
                    </svg>
                    <!--<span class="postlink-title">Visit site</span>-->
                  </a>
                </li>
                <li class="post-link" v-if="postdata.githublink">
                  <a :href="postdata.githublink" target="_blank" title="View on GitHub" tabindex="2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512">
                      <path d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"/></svg>
                    <!--<span class="postlink-title">View on GitHub</span>-->
                  </a>
                </li>
              </ul>
            </div>
          </div>
          </Transition>
          <nav v-if="innerpageshowing && notintro" class="postnav-menu">
            <ul class="postnavmenu flex-inline">
              <li> <a id="nextpost" :href="'#/portfolio:' + postdata.nextpost" :class="{disabled: postdata.nextpost == null}" :disabled="postdata.nextpost == null" tabindex="3">
                <svg id="left-chevron" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.7 91.2">
                  <g>
                    <polygon points="45.7 74.3 16.86 45.56 45.6 16.9 45.6 1 1 45.6 8.9 53.5 8.95 53.45 45.7 90.2 45.7 74.3"/>
                  </g>
                  </svg>
                </a> </li>
              <li> <a id="previouspost" :href="'#/portfolio:' + postdata.previouspost" :class="{disabled: postdata.previouspost == null}" :disabled="postdata.previouspost == null" tabindex="3">
                <svg id="right-chevron" class="icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46.6 91.2">
                  <g>
                    <polygon points="37.7 37.7 37.65 37.75 1 1 1 16.9 29.74 45.64 1 74.3 1 90.2 45.6 45.6 37.7 37.7"/>
                  </g>
                  </svg>
                </a> </li>
            </ul>
          </nav>
        </div>
      
      <!--<nav v-if="innerpageshowing && notintro">
        <ul class="postnav-menu">
          <li v-for="(post, index) in posts" class="postnav-menu-item" :key="index">
            <a :href="'#/portfolio:' + post.postid" :title="post.posttitle" :class="{active: post.posttitle == postdata.posttitle}">&nbsp;</a>
          </li>
        </ul>
      </nav>-->
      
		</div>
	</div>
	<div class="loader" :class="{hidden: !showloader}">
		<div class="load-icon">
			<svg id="loadbrackets" ref="loadbrackets" data-name="load brackets" class="load-brackets" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.13 100.65">
				<defs>
					<lineargradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="58.13" y1="93" x2="58.13" y2="-9.094947e-13">
						<stop  offset="0" style="stop-color:rgba(0,0,0,.05)"/>
						<stop  offset="1" style="stop-color:rgba(0,0,0,.5)"/>
					</lineargradient>
				</defs>
				<g id="loaderbrackets" ref="loaderbrackets" data-name="loaderbrackets" class="loader-brackets">
					<g>
						<g id="loaderbracket1" ref="loaderbracket1" data-name="bracket 1" class="load-bracket">
							<path class="loader-bracket" d="M26.26,49.62L116.26,0h-46L5.93,36.83,0,64.37h0l45.41,28.63,26.15-14.35-45.3-29v-.03ZM7,37.64L70.58,1.25h40.82L25.65,48.52,1.81,61.92l5.19-24.28Z"/>
						</g>
						<g id="loaderbracket2" ref="loaderbracket2" data-name="bracket 2" class="load-bracket">
							<path class="loader-bracket" d="M144.87,51.03l-90,49.62h46l64.33-36.83,5.93-27.54h0L125.72,7.65l-26.15,14.35,45.3,29v.03ZM164.13,63.01l-63.58,36.39h-40.82l85.75-47.27,23.84-13.4-5.19,24.28Z"/>
						</g>
					</g>
				</g>
			</svg>
		</div>
		<div id="loadtext" ref="loadtext" class="load-text">&plus;</div>
		<div id="loadtext2" ref="loadtex2" class="load-text2">&nbsp;LOADING</div>
	</div>
</div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>

<script>
import { ref } from "vue";
import { gsap } from "gsap";

const postdata = ref(Object);
const showlogo = ref(false);
const showloader = ref(false);
const postloaded = ref(false);
const showvideo = ref(false);
const loadvideo = ref(false);
const posts = ref(null);
const notintro = ref(false);
const logoclicks =ref(0);
const urlchat = [
  "Hello",
  "Hey-there",
  "Howdy",
  "Hi",
  "Sup",
  "Yo",
  "",
];

export default {
  name: "ForegroundContent",
  props: {
   innerpageshowing: Boolean
  },
  data() {
    return {
      showlogo,
      showloader,
      postloaded,
      postdata,
      showvideo,
      loadvideo,
      posts,
      notintro
    }
  },
  mounted(){
    this.showLoader();
  },
  methods: {
    setPosts: function(postdata){
      posts.value = postdata;
      //console.log(posts);
    },

    logoClick: function(ipshow){
      if(ipshow){
        window.location.hash = '/:' + postdata.value.thispost;
        this.hideInnerContent();
      } else {
        window.location.hash = '/:' + urlchat[logoclicks.value];
        logoclicks.value ++;
        (logoclicks.value >= urlchat.length) ? logoclicks.value = 0 : null;
        this.$root.scrollToTarget(this.$root.$refs.hero);
      }
    },

    closeClick: function(){
      if(showvideo.value != true){
        window.location.hash = '/:' + postdata.value.thispost;
        this.hideInnerContent();
      } else {
        this.showVideo("close");
      }
    },

    showVideo: function(toggle){
      let videointro;
      let scope = this;
      if(showvideo.value != true && toggle != "close"){ 
        showvideo.value = true;
        this.$nextTick(() => {

          videointro = gsap.timeline();

          videointro.fromTo(this.$refs.video, { 
            scale: .3,
            y: "30vh",
            opacity: 1,
          },
          {
            scale: .3,
            y: 0,
            opacity: 1,
            duration: .5,
            ease: "back.out"
          })
          .fromTo(this.$refs.video, { 
            scale: .8,
          },
          {
            scale: 1,
            duration: .25,
            ease: "power2.out",
            onStart: function(){
              gsap.set(scope.$refs.video, { background: "#000" })
            },
            onComplete: function(){
              loadvideo.value = true;
              videointro = null;
            }
          });
        });

      } else {
        
        videointro = null;
        loadvideo.value = false;
        showvideo.value = false;
      
      }
    },

    showPost: function(post, firstload){
      loadvideo.value = false;
      postloaded.value = false;
      this.showVideo("close");
      (firstload == true) ? showlogo.value = true : this.showLoader();

      gsap.to(this.$refs.innerpagewrap, {
        duration: 0.35,
        scrollTop: 0,
        ease: "power3.out",
      });

      if(!this.$root.innerpageShowing){
        this.revealInnerContent();
      }

      let scope = this;
      gsap.delayedCall(.8, function(){
        notintro.value = true;
        fetch('https://www.keefstudio.com/wp-json/keefstudio/v1/getpostdata/?thepostid=' + post, {
          method: "GET",
        })
        .then((response) => {
          response.json().then((data) => {
            postdata.value = JSON.parse(data);
            console.log(postdata.value);
            scope.hideLoader();
            scope.$refs.innerpagecontent.focus();
            gsap.delayedCall(.8, () => {
              postloaded.value = true;
            });
          });
        
        })
        .catch((err) => {
          console.error(err);
        });
      });

    },

    revealInnerContent: function(){
      this.$parent.innerpageShowing = true;
      this.$parent.pauseScrolling(true);

      /*gsap.to(this.$refs.angleleft, {
        duration: 1,
        x: 0,
        ease: "power3.in"
      });

      gsap.to(this.$refs.angleright, {
        duration: 1,
        x: 0,
        ease: "power3.in"
      });*/
    },

    hideInnerContent: function(){
      notintro.value = false;
      this.hideLoader();
      this.$refs.innerpagecontent.focus();
      postloaded.value = false;
      showlogo.value = true;
      this.showVideo('close');
      this.$parent.innerpageShowing = false;
      this.$parent.pauseScrolling(false);
      
      gsap.to(this.$refs.innerpagewrap, {
        duration: 0.35,
        scrollTop: 0,
        ease: "power3.out",
        delay: .15
      });

      /*gsap.to(this.$refs.angleleft, {
        duration: 1,
        x: (window.innerHeight * -.36) - (window.innerWidth * .50),
        ease: "power3.out"
      });

      gsap.to(this.$refs.angleright, {
        duration: 1,
        x: (window.innerHeight * .46) + (window.innerWidth * .50),
        ease: "power3.out"
      });*/
    },
    
    showLoader: function() {
      showloader.value = true;

      gsap.killTweensOf(this.$refs.loaderbracket1);
      gsap.killTweensOf(this.$refs.loaderbracket2);
      gsap.killTweensOf(this.$refs.loadtext);

      gsap.set(this.$refs.loaderbracket1, { x: -50 });
      gsap.set(this.$refs.loaderbracket2, { x: 50 });
      gsap.set(this.$refs.loadbrackets, { rotationY: "0deg" });

      gsap.to(this.$refs.loaderbracket1, {
        duration: 1,
        delay: 0.35,
        opacity: 1,
        x: 0,
        ease: "power3.out",
      });
      gsap.to(this.$refs.loaderbracket2, {
        duration: 1,
        delay: 0.35,
        opacity: 1,
        x: 0,
        ease: "power3.out",
      });
      gsap.to(this.$refs.loadtext, {
        duration: 0,
        delay: 0.5,
        opacity: 1,
        ease: "none",
        repeat: -1,
        repeatDelay: 0.05,
        yoyo: "true",
      });
    },

    hideLoader: function() {
      let scope = this;
      gsap.killTweensOf(this.$refs.loaderbracket1);
      gsap.killTweensOf(this.$refs.loaderbracket2);
      gsap.killTweensOf(this.$refs.loadtext);

      gsap.set(this.$refs.loadtext, { opacity: 0 });

      gsap.to(this.$refs.loadbrackets, {
        duration: 0.5,
        rotationY: "90deg",
        ease: "power2.in",
        onComplete: function () {
          gsap.set(scope.$refs.loaderbracket1, { x: 0, opacity: 0 });
          gsap.set(scope.$refs.loaderbracket2, { x: 0, opacity: 0 });
          gsap.set(scope.$refs.loadbrackets, { rotationY: "0deg" });
          showloader.value = false;
        }
      });

    }
  },
};
</script>
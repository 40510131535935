<template>
<!-- Main scroll wrapper - used by GSAP -->
<div id="smooth-wrapper" ref="smoothwrapper" class="main-outer-wrap">
	<div id="smooth-content" ref="smoothcontent" class="main-inner-wrap">
		<main>
			<section id="hero" ref="hero" class="full-screen hero-section" tabindex="0">
				<div class="outer-content">
					<div class="inner-content">
						<nav id="mainnav" class="main-nav">
							<ul class="flex-inline">
								<li><a href="#/intro" @click="scrollToTarget(this.$refs.intro)"><i>3.</i><span>ABOUT</span></a></li>
								<li><a href="#/portfolio" @click="scrollToTarget(this.$refs.portfolio)"><i>2.</i><span>PORTFOLIO</span></a></li>
								<li><a href="#/bio" @click="scrollToTarget(this.$refs.bio)"><i>1...</i><span>MY JOURNEY</span></a></li>
							</ul>
						</nav>
						<h2> LAUNCHING NEXT&#8209;GEN<br class="m-hide">
							WEB EXPERIENCES </h2>
						<p>Using modern web technology, old-school know how, and design expertise&mdash;I build functional and compelling web sites, landing pages, email campaigns, and web apps&mdash;from scratch.</p>
					</div>
				</div>
				<div ref="scrolldown" class="scroll-down" @click="scrollToTarget(this.$refs.intro)" tabindex='-1'>
					<svg id="downarrow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 91.2 46.7">
						<g>
							<polygon points="53.5 37.7 53.5 37.7 90.2 1 74.3 1 45.6 29.8 16.9 1 1 1 45.6 45.7 53.5 37.7"/>
						</g>
					</svg>
				</div>
			</section>
			<section id="intro" ref="intro" class="intro-section" tabindex="0">
				<div class="outer-content">
					<div class="inner-content center">
						<div class="side-line-heading">
							<h4>A PARTICULAR SET OF SKILLS</h4>
						</div>
						<p>I have an art/design background in web, print, and illustration&mdash;a love for coding and technology&mdash;and a nack for bringing the two together. I am good at visualizing objects, in space, that behave according to a precise and quirky set of rules, and have had a lot of practice.</p>
						<!--<div class="side-line-heading">
							<h4>ADVANCED FRONTEND DEVELOPMENT</h4>
						</div>
						<p>From the old days of hand coding galaxies of tables, to using modern javascript frameworks, I have enjoyed the entire journey&mdash;along the way picking up handy skills such as creating CMS themes, using APIs and templating engines, problem solving with RegX, as well as video editing and animation. I've been at this awhile and the diverse experience comes in handy.</p>-->
					</div>
				</div>
				<div id="introbg" ref="introbg" class="intro-bg" data-speed="auto"></div>
			</section>
			<!-- Orbit Path Static -->
			<div id="orbitpaths" ref="orbitpaths" class="orbit-path-static-outer" :class="{ activated: !orbitActive }">
				<div class="orbit-path-static-inner">
					<div id="orbitcenter" ref="orbitcenter" class="orbit-center" @click="scrollToTarget(this.$refs.portfolio)">
						<svg id="Spinner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.73 80.01">
							  <g id="circle1" ref="circle1">
								<g ref="circle1inner" class="inner-group">
									<path d="M62.86,43H16.86c-.21,0-.42,.09-.55,.26-.14,.16-.2,.37-.17,.58,2.02,13.1,14.28,22.08,27.38,20.06,10.35-1.59,18.47-9.72,20.06-20.06,.03-.21-.03-.42-.17-.58-.13-.17-.34-.26-.55-.26Z"/>
									<path d="M16.86,37H62.86c.21,0,.42-.09,.55-.26,.14-.16,.2-.37,.17-.58-2.02-13.1-14.28-22.08-27.38-20.06-10.35,1.59-18.47,9.72-20.06,20.06-.03,.21,.03,.42,.17,.58,.13,.17,.34,.26,.55,.26Z"/>
								</g>
							  </g>
							  <g id="circle2" ref="circle2">
								<g ref="circle2inner" class="inner-group">
									<path d="M46.8,2.81c15.83,2.95,28.07,15.6,30.49,31.52,.05,.36,.35,.62,.71,.62h1c.21,0,.41-.09,.55-.25,.14-.16,.2-.37,.17-.58C77.2,17.03,64.05,3.46,47.06,.4c-.21-.04-.43,.02-.59,.16-.17,.14-.26,.34-.26,.56v1c.02,.34,.26,.62,.59,.69Z"/>
									<path d="M35.28,0C17.16,2.09,2.7,16.08,.03,34.12c-.03,.21,.03,.42,.17,.58,.14,.16,.34,.25,.55,.25H1.75c.36,0,.66-.26,.71-.62C5.04,17.49,18.54,4.43,35.46,2.42c.37-.04,.64-.35,.64-.72V.7c0-.21-.09-.4-.24-.54-.16-.13-.37-.18-.58-.15Z"/>
									<path d="M35.44,77.6c-16.92-2.01-30.42-15.07-33-31.91-.05-.36-.35-.62-.71-.62H.73c-.21,0-.41,.09-.55,.25-.14,.16-.2,.37-.17,.58,2.68,18.04,17.15,32.04,35.27,34.11h.08c.18,0,.35-.06,.48-.18,.15-.14,.24-.33,.24-.54v-1c0-.36-.28-.65-.64-.69Z"/>
									<path d="M78.97,45.07h-1c-.36,0-.66,.26-.71,.62-2.42,15.91-14.64,28.56-30.46,31.52-.34,.07-.58,.36-.59,.71v1c0,.4,.32,.72,.72,.73h.13c16.98-3.08,30.11-16.64,32.63-33.72,.03-.21-.03-.42-.17-.58-.13-.17-.33-.27-.55-.28Z"/>
								</g>
							  </g>
						  </svg>
					</div>
					<div id="orbitpathstatic" class="orbit-path">
						<div class="orbit-svg">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2130.11 727.38">
								<g>
									<path class="orbit-1" d="M3.48,3.59s743.06,718.79,1543.65,718.79c594.57,0,790.42-475.17,301.21-491.04-366.6-11.89-784.04,131.64-784.04,131.64"/>
								</g>
							</svg>
						</div>
					</div>
				</div>
			</div>
			<span id="portfolio" ref="portfolio" data-info="this is just a layout marker"></span>
			<PortfolioSection ref="portfoliosection" />
			<TechSection ref="techsection" />
			<section id="bio" ref="bio" class="bio-section" data-speed=".9" tabindex="0">
				<div class="outer-content">
					<div class="inner-content">
						<h3>MY JOURNEY</h3>
						<p>I'm an artist at heart, who cares about how things, look, feel, and function. My journey continually drives me deeper into technology: to find the skills and solutions required to bring vision into reality. I love to help people, solve problems, and make a difference.</p>
						<ul class="flex-2-col journey-list">
							<li>
								<h5>'00-05</h5>
								<p>This period I was mainly involved in print and product deign, with an emphasis on advertising, packaging, photography, and screen printing. I also began learning early web technology and video editing.</p>
							</li>
							<li>
								<h5>'05-10</h5>
								<p>I moved into a more full-time freelance role as a webmaster, learning more advanced web techniques, server configuration, Content Management Systems, Flash and the fundamentals of Object Oriented Programing.</p>
							</li>
							<li>
								<h5>'10-15</h5>
								<p>As my specialty on the web (Flash) faded into oblivion, I refocused on semantic and mobile-friendly web technology, including: responsive design, and popular libraries such as Bootstrap &amp; jQuery, and integrating projects with internet-marketing systems.</p>
							</li>
							<li>
								<h5>'15-20</h5>
								<p>My current efforts, amidst the rise of JavaScript frameworks, APIs, and command line interfaces, are focused on leveraging current technology to build dynamic websites and web applications from the ground up.</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</main>
		<footer>
			<section id="footer" ref="footer" class="footer-section" data-speed="1.1">
				<div class="outer-content">
					<div class="inner-content center">
						<h3>Now what?</h3>
						<ul class="flex-inline footer-links">
							<li>
								<a href="https://www.keefstudio.com/gallery/" target="_blank" title="View art gallery">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
										<path d="M339.3 367.1c27.3-3.9 51.9-19.4 67.2-42.9L568.2 74.1c12.6-19.5 9.4-45.3-7.6-61.2S517.7-4.4 499.1 9.6L262.4 187.2c-24 18-38.2 46.1-38.4 76.1L339.3 367.1zm-19.6 25.4l-116-104.4C143.9 290.3 96 339.6 96 400c0 3.9 .2 7.8 .6 11.6C98.4 429.1 86.4 448 68.8 448H64c-17.7 0-32 14.3-32 32s14.3 32 32 32H208c61.9 0 112-50.1 112-112c0-2.5-.1-5-.2-7.5z"/>
									</svg>
								</a>
							</li>
							<li>
								<a href="https://github.com/keefstudio" target="_blank" title="View on GitHub">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480 512">
										<path d="M186.1 328.7c0 20.9-10.9 55.1-36.7 55.1s-36.7-34.2-36.7-55.1 10.9-55.1 36.7-55.1 36.7 34.2 36.7 55.1zM480 278.2c0 31.9-3.2 65.7-17.5 95-37.9 76.6-142.1 74.8-216.7 74.8-75.8 0-186.2 2.7-225.6-74.8-14.6-29-20.2-63.1-20.2-95 0-41.9 13.9-81.5 41.5-113.6-5.2-15.8-7.7-32.4-7.7-48.8 0-21.5 4.9-32.3 14.6-51.8 45.3 0 74.3 9 108.8 36 29-6.9 58.8-10 88.7-10 27 0 54.2 2.9 80.4 9.2 34-26.7 63-35.2 107.8-35.2 9.8 19.5 14.6 30.3 14.6 51.8 0 16.4-2.6 32.7-7.7 48.2 27.5 32.4 39 72.3 39 114.2zm-64.3 50.5c0-43.9-26.7-82.6-73.5-82.6-18.9 0-37 3.4-56 6-14.9 2.3-29.8 3.2-45.1 3.2-15.2 0-30.1-.9-45.1-3.2-18.7-2.6-37-6-56-6-46.8 0-73.5 38.7-73.5 82.6 0 87.8 80.4 101.3 150.4 101.3h48.2c70.3 0 150.6-13.4 150.6-101.3zm-82.6-55.1c-25.8 0-36.7 34.2-36.7 55.1s10.9 55.1 36.7 55.1 36.7-34.2 36.7-55.1-10.9-55.1-36.7-55.1z"/></svg>
								</a>
							</li>
							<li>
								<a href="https://www.linkedin.com/in/cameron-okeefe-16399b5/" target="_blank" title="LinkedIn profile">
									<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
										<path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"/></svg>
								</a>
							</li>
						</ul>
					</div>
				</div>
			</section>
		</footer>
	</div>
</div>
<!-- Fixed elements -->
<div class="background-video overlay-pattern">
	<video ref="launchpadvideo" autoplay="true" muted="true" playsinline="true" loop="true" src="https://www.keefstudio.com/wordpress/wp-content/uploads/2020/07/launchpad-loop_3.mp4" poster="./assets/launchpad-poster.jpg"></video>
</div>
<ScrollAnimations id="scrollanimations" ref="scrollanimations" :orbitactive="orbitActive"></ScrollAnimations>
<ForegroundContent ref="foregroundcontent" :innerpageshowing="innerpageShowing"></ForegroundContent>
</template>

<script>
import { ref } from "vue";
import PortfolioSection from "./components/PortfolioSection.vue";
import TechSection from "./components/TechSection.vue";
import ForegroundContent from "./components/ForegroundContent.vue";
import ScrollAnimations from "./components/ScrollAnimations.vue";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollSmoother } from "gsap/ScrollSmoother";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin( ScrollTrigger, ScrollSmoother, DrawSVGPlugin, ScrollToPlugin  );

//import * as THREE from "three";
//import { OBJLoader } from "three/addons/loaders/OBJLoader.js";

const orbitActive = ref(false);
const innerpageShowing = ref(true);
let scrolling = ref(true);
let scrollsmoother = null;

const routes = {
	'#/': 'hero',
	'#/intro': 'intro',
	'#/portfolio': 'portfolio',
	'#/bio': 'bio'
};

let currentpath = null;

export default {
  name: "App",
  components: {
    PortfolioSection,
	TechSection,
	ForegroundContent,
    ScrollAnimations,
  },
  data() {
    return {
    	orbitActive,
		innerpageShowing,
		scrollsmoother,
		scrolling,
		currentpath,
    }
  },
  mounted(){
		scrolling.value = false;
      	orbitActive.value = false;
		currentpath =  window.location.hash;
      	this.triggerPaths();
	  	this.triggerStarfield();

		let params = new URLSearchParams(document.location.search);
		if (params.get("scroll") != "no"){
			this.scrollSmoother();
		}
		//this.triggerOrbiter();

		window.addEventListener('hashchange', () => {
		  currentpath = window.location.hash;
		  this.doRoute();
		});

		let scope = this;
		gsap.delayedCall(1.5, function(){
			scope.$root.doRoute(true);
		});
  },

  methods: {
	doRoute: function(firstload){
		let route = currentpath.split(':');
		let scrolltarget = routes[route[0] || '#/'] || null;
		if(scrolltarget != null){
			if(scrolltarget == "portfolio" && route[1]){
				this.$refs.foregroundcontent.showPost(route[1], firstload);
				//this.scrollToTarget(this.$refs[scrolltarget]);
			} else {
				this.$refs.foregroundcontent.hideInnerContent();
				if(firstload == true){
					//window.location.hash = '/';
					//this.$refs.foregroundcontent.hideInnerContent();
					//this.scrollToTarget(this.$refs[scrolltarget]);
				} else {
					if(route[1]){
						//console.log(route[1]);
						//this.$refs.portfoliosection.$refs['portfoliotitle'].focus();
						//this.$refs.portfoliosection.$refs[route[1]].focus();
					}
				}
			}
		} else {
			//window.location.hash = '/';
			this.$refs.foregroundcontent.hideInnerContent();
		}
		
		
	},

    scrollSmoother: function(){
      ScrollTrigger.normalizeScroll(true);
      ScrollTrigger.clearScrollMemory("manual");
      window.history.scrollRestoration = "manual";
	
	  //let scope = this;
      scrollsmoother = ScrollSmoother.create({
        smooth: .6,
        speed: 1,
        effects: true,
        //smoothTouch: 0.1,
      });
      this.pauseScrolling(true);   
    },

	pauseScrolling: function(paused){
		scrolling.value = paused;
		if(scrollsmoother != null){
			scrollsmoother.paused(paused);
		}
	},

	scrollToTarget: function(target){
		let scope = this;
		console.log(target);
		if(scrollsmoother != null){
			let scrollY = scrollsmoother.scrollTop();
			let offsetTop = scrollsmoother.offset(target);

			if(target.id == 'intro'){
				offsetTop = window.innerHeight;
			}

			gsap.killTweensOf(scrollsmoother);
			gsap.to(scrollsmoother, {
				scrollTop: offsetTop,
				duration: Math.abs(scrollY - offsetTop) * .001,
				ease: "slow",
				onComplete: function(){
					switch(target.id) {
						case 'hero':
							scope.$refs.hero.focus();
							break;
						case 'intro':
							scope.$refs.intro.focus();
							break;
						case 'portfolio':
							scope.$refs.portfoliosection.$refs.portfolio.focus();
							break;
						case 'bio':
							scope.$refs.bio.focus();
							break;
					}
				}
				});
		} else {
			let scrollY = window.scrollY;
			let offsetTop = target.offsetTop;
			gsap.killTweensOf(window);
			gsap.to(window, {
				duration: Math.abs(scrollY - offsetTop) * .001,
				scrollTo: offsetTop,
				ease:"slow",
				onComplete: function(){
					switch(target.id) {
						case 'hero':
							scope.$refs.hero.focus();
							break;
						case 'intro':
							scope.$refs.intro.focus();
							break;
						case 'portfolio':
							scope.$refs.portfoliosection.$refs.portfolio.focus();
							break;
						case 'bio':
							scope.$refs.bio.focus();
							break;
					}
				}
			});
		}
	},

    toggleOrbit: function(isactive){
      orbitActive.value = isactive;

      let orbitcenter = this.$refs.orbitcenter;
      let circle1 = this.$refs.circle1;
      let circle2 = this.$refs.circle2;
      let circle1inner = this.$refs.circle1inner;
      let circle2inner = this.$refs.circle2inner;

      if(!isactive){
        gsap.killTweensOf(orbitcenter);
        gsap.killTweensOf(circle1);
        gsap.killTweensOf(circle2);
        gsap.killTweensOf(circle1inner);
        gsap.killTweensOf(circle2inner);
        
        gsap.set(circle1inner, {rotation: 0, svgOrigin:"40 40"});
        gsap.set(circle2inner, {rotation: 0, svgOrigin:"40 40"});
        gsap.to(circle2inner, {duration: .35, rotation:-90, svgOrigin:"40 40", ease:"none", repeat:-1});
        gsap.to(circle1inner, {duration: 2, rotation:-180, ease: "back.out", svgOrigin:"40 40", repeat:-1, delay:1.5, repeatDelay: .5});
        
        gsap.set(circle1, {opacity:1, scale: 1});
        gsap.set(circle2, {opacity:1, scale: 1});
        gsap.to(orbitcenter, {duration:.35, scale:1, ease:"power2.out"});
        gsap.from(circle2, {duration: .5, scale:0, ease: "power2.out", svgOrigin:"40 40", delay:.15});
        gsap.from(circle1, {duration: .5, scale:0, ease:"power2.out", svgOrigin:"40 40", delay:.35});
      } else {
        gsap.killTweensOf(orbitcenter);
        gsap.to(orbitcenter, {duration:.35, scale:.65, ease:"power2.out"});
        
        gsap.killTweensOf(circle1);
        gsap.killTweensOf(circle2);
        gsap.killTweensOf(circle1inner);
        gsap.killTweensOf(circle2inner);

        gsap.set(circle1, {opacity:0});
        gsap.set(circle2, {opacity:0});
      }
    },

    triggerPaths: function(){
      let scope = this;
      let orbit1 = this.$refs.scrollanimations.$refs.orbit1;
      let orbit2 = this.$refs.scrollanimations.$refs.orbit2;
      let orbit3 = this.$refs.scrollanimations.$refs.orbit3;
      
      let orbitpaths = gsap.timeline({
        scrollTrigger: {
          trigger: this.$refs.hero,
          start: "top top",
          endTrigger: this.$refs.intro,
          end: "bottom 50%-=4px",
          scrub: true,
          //markers: true,
          onUpdate: function(self){
            let scrollv = self.progress - (self.getVelocity() * .00002);
            gsap.set(orbit2, {drawSVG: (scrollv * 99) + "% " + (self.progress * 99) + "%" });
          },
		  /*onEnter: function(){
			window.location.hash = "/intro";
		  },
		  onLeave: function(){
			window.location.hash = "/portfolio";
		  },
		  onEnterBack: function(){
			window.location.hash = "/intro";
		  },
		  onLeaveBack: function(){
			window.location.hash = "/";
		  },*/
          onToggle: function(self){
            scope.toggleOrbit(self.isActive);
          }
        }
      });

      gsap.set(orbit1, {drawSVG: "0% 0%"});
      gsap.set(orbit2, {drawSVG: "0% 0%"});
      gsap.set(orbit3, {drawSVG: "0% 0%"});

      orbitpaths.to(orbit1, {
        drawSVG: "0% 99%",
        ease: "none",
        duration: 1
      }, 0)
      .to(orbit3, {
        drawSVG: "99% 99%",
        ease: "none",
        duration: 1
      }, 0);

	  gsap.to(this.$refs.scrolldown, {
		scrollTrigger: {
          trigger: this.$refs.hero,
          start: "top top",
          endTrigger: this.$refs.intro,
          end: "top 80%",
          scrub: true,
		},
		opacity:0
	  })

    },

	triggerOrbiter: function(beacon){
		let scope = this;
		let orbiterTrigger = gsap.timeline({
        	scrollTrigger: {
				trigger: this.$refs.portfoliosection.$refs.portfolio,
				start: "top 48%",
				endTrigger: this.$refs.techsection.$refs.tech,
				end: "bottom 45%",
				scrub: true,
				markers: false,
				onUpdate: function(){
					//scope.$refs.scrollanimations.scrollOrbiter(self.progress);
				},
				onToggle: function(){
					scope.$refs.scrollanimations.toggleBeacons("beacon");
					scope.$refs.scrollanimations.toggleBeacons("beacon2");
				},
				onEnter: function(){
					scope.$refs.launchpadvideo.pause();
				},
				onLeaveBack: function(){
					scope.$refs.launchpadvideo.play();
				},
				/*onLeave: function(){
					//window.location.hash = "/bio";
					scope.$refs.scrollanimations.toggleBeacons("beacon");
				},
				onEnterBack: function(){
					window.location.hash = "/portfolio";
					scope.$refs.scrollanimations.toggleBeacons("beacon");
				}*/
			}
		});
		orbiterTrigger.pause();
		orbiterTrigger.to(beacon.position, {duration:2, x:-1.7, y:0.3, z:0, ease: "power3.out"}, 0);
		orbiterTrigger.to(this.$refs.scrollanimations.$refs.orbiter, {duration:1, opacity:1, ease: "power2.inout"}, 0);
		orbiterTrigger.to(beacon.rotation, {duration:3.5, y:1, ease: "power3.in"}, 3);
		orbiterTrigger.to(beacon.position, {duration:3, x:1.7, z:1.5, y:.1, ease: "power3.in"}, 3.5);
	},

	triggerStarfield: function(){
		let starfieldTrigger = gsap.timeline({
				scrollTrigger: {
					trigger: this.$refs.intro,
					start: "top 100%",
					endTrigger: this.$refs.bio,
					end: "top 0%",
					scrub: true,
					markers: false
				}
			});
			starfieldTrigger.pause();
			starfieldTrigger.to(this.$refs.scrollanimations.$refs.starfield, { duration: 1, opacity:1 });
	},
  },
};
</script>